// Set your brand colors
$black: #121619;
$orange: #f06449;
$green: #48a9a6;
$grey: #e4dfda;
$pink: #c60f7b;

// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$primary: $green;
$secondary: $grey;
$link: $orange;

@import '~bulma/bulma.sass';

.burger-icon-is-active > span:nth-child(3) {
  transform: translateY(-5px) rotate(90deg);
}

.burger-icon-is-active > span:nth-child(2) {
  visibility: hidden;
}

.burger-icon-is-active > span:nth-child(1) {
  transform: translateY(5px);
}

.burger-icon-is-active {
  transform: translateY(26px) rotate(45deg);
}

.anchor {
  display: block;
  position: relative;
  top: 0rem;
  visibility: hidden;
}

.anchor-fixed-nav {
  display: block;
  position: relative;
  top: -3.25rem;
  visibility: hidden;
}

.modal-card,
.modal-content {
  /*remove width: 100%;*/
  width: calc(100% - 2em);
  max-width: 640px;
}

.card.workout-view {
  margin-top: 2rem;
  padding: 2rem;
  background-color: transparent;
}

.table.workout-view.is-bordered {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
